import React, { useState } from 'react';
import classNames from 'classnames';
import { isColorDark } from '@/utils';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css'; // Required for styles

const MenuItem = ({ item, className, backgroundColour, ...props }) => {
  const dividerColor = isColorDark(backgroundColour) ? 'bg-secondary-coolsand' : 'bg-primary';
  const [showEmbed, setShowEmbed] = useState(false);

  return (
    <div
      className={classNames(
        `bg-secondary-general/10 hover:bg-secondary-general/50 transition-all duration-1000 py-6 px-4
            flex justify-between
        `,
        className,
      )}
      {...props}
    >
      {/* Menu Item Details */}
      <div className="menu-item-details flex flex-col justify-between">
        <p
          className="font-ivy-ora text-title-s not-italic font-normal leading-32 -tracking-wider"
          role="heading"
          aria-level="3"
        >
          {item.title}
        </p>

        <div className="time-wrapper flex items-center relative">
          <p className="font-cera text-tag-m not-italic font-normal leading-24">{item.datePeriod}</p>
          {item.datePeriod && item.timePeriod && (
            <div className={classNames('divider rounded-full mt-1 w-[2px] h-[2px] mx-4', dividerColor)}></div>
          )}
          <p className="font-cera text-tag-m not-italic font-normal leading-24">{item.timePeriod}</p>
        </div>
      </div>

      {/* PDF Viewer Button */}
      <div className="menu-link hover:underline text-right tertiary-button-wrapper self-end max-w-[90px] lg:max-w-[unset]">
        <button onClick={() => setShowEmbed(true)}>{item.ctalink?.text}</button>
      </div>

      {showEmbed && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-10 rounded-lg w-[90%] h-[90%] relative shadow-lg">
            <button
              onClick={() => setShowEmbed(false)}
              className="absolute top-2 right-2 text-white bg-red-500 px-3 py-1  rounded"
            >
              ✕ Close
            </button>

            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
              <div className="h-[90%] overflow-auto">
                <Viewer fileUrl={item.ctalink?.href} />
              </div>
            </Worker>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuItem;
