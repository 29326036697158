import React from 'react';
import { HeadingTag, ModuleBase } from '@/components';
import MenuItem from './MenuItem';
import classNames from 'classnames';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';

const MenuModule = ({ data }) => {
  const { headingTitle, firstColumn, secondColumn, backgroundColour } = data || {};

  return (
    <ModuleBase data={data} className="font-cera relative" role="contentinfo" aria-label="menu">
      <FadeUpStarter className="main-menu-wrapper container color-from-bg -mb-12 lg:-mb-6">
        {headingTitle && (
          <FadeUpAnimation>
            <HeadingTag
              data={headingTitle}
              className={classNames(
                `color-from-bg font-ivy-ora mb-[53px] lg:mb-16 text-center text-title-m not-italic font-normal leading-headingReg -tracking-wide
                lg:text-title-l lg:leading-60 lg:-tracking-normal`,
              )}
            />
          </FadeUpAnimation>
        )}

        <div className="column-wrapper flex flex-col  lg:flex-row lg:justify-between">
          <div className="first-menu-column color-from-bg lg:w-[928px]">
            <FadeUpAnimation>
              <p className="text-tag-m not-italic font-normal leading-24 font-cera mb-6">{firstColumn?.title}</p>
            </FadeUpAnimation>

            <div className="first-column-wrapper flex flex-col  lg:flex-row lg:flex-wrap">
              {firstColumn?.menuSections.map((menuItem, menuIndex) => (
                <FadeUpAnimation key={menuIndex}>
                  <MenuItem
                    item={menuItem}
                    backgroundColour={backgroundColour}
                    className={classNames(`w-full h-[109px] lg:w-[432px] rounded-lg mb-6 lg:mr-8`)}
                  />
                </FadeUpAnimation>
              ))}
            </div>
          </div>

          <div className="second-menu-column color-from-bg">
            <FadeUpAnimation>
              <p className="text-tag-m not-italic font-normal leading-24 font-cera mb-6">{secondColumn?.title}</p>
            </FadeUpAnimation>

            <div
              className={`second-column-wrapper flex flex-col lg:gap-x-8 ${!secondColumn?.title ? 'mt-12' : 'mb-6'}`}
            >
              {secondColumn?.menuSections.map((menuItem, menuIndex) => (
                <FadeUpAnimation key={menuIndex}>
                  <MenuItem
                    item={menuItem}
                    backgroundColour={backgroundColour}
                    className={classNames(`w-full h-[109px] lg:w-[432px] rounded-lg mb-6`)}
                  />
                </FadeUpAnimation>
              ))}
            </div>
          </div>
        </div>
      </FadeUpStarter>
    </ModuleBase>
  );
};

export default MenuModule;
